import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { includes, get } from 'lodash';
import { IP_API_URL, FUNCTIONS_URL, CANADACODE, USCODE, KUWAITCODE } from './country-handler-constants';
import { ApiService } from '../api/api.service';
import { environment } from '@lsworks/data';
import { NEURALIGNUSA, NEURALIGN_ACADEMY } from '../../../app.constants';
import {
  RegionData,
  Organization,
  OrgResponse,
  RegionResponse,
  IpServiceResponse,
} from './interfaces/country-handler.interfaces';

@Injectable({
  providedIn: 'root',
})
export class CountryHandlerService {
  private _userCountry = null;
  private isReservedRegion = false;
  private regionOwner: Organization;
  private regionData: RegionData;
  private b2cTokenPrice = 125;
  private b2cPaymentType = 'Monthly installment payment';
  private b2cCurrency = 'CAD';
  public fullTokenPrice = 1500;

  constructor(private _http: HttpClient, private _api: ApiService) {}

  public async initCountry() {
    if (this._userCountry) {
      return this._userCountry;
    } else {
      // netlify geolocation dont work locally , so we use the IP API instead

      if (includes(window.location.href, 'localhost')) {
        try {
          const resp: IpServiceResponse = await this._http.get<IpServiceResponse>(IP_API_URL).toPromise();
          this._userCountry = get(resp, 'countryCode', CANADACODE);
        } catch (error) {
          console.error(error);
        }
      } else if (includes(window.location.href, 'deploy-preview')) {
        this._userCountry = CANADACODE;
      } else {
        // @TODO Create functions url as a envinronment variable

        try {
          const resp: string = await this._http.get<string>(FUNCTIONS_URL).toPromise();

          this._userCountry = resp;
        } catch (error) {
          console.error(error);
        }
      }

      if (!this._userCountry) {
        this._userCountry = CANADACODE;
      }
    }

    await this.checkRegion();
  }

  public async checkRegion() {
    try {
      await this._http
        .get(environment.API_URL + 'region/checkIfIsReserved/' + this._userCountry)
        .toPromise()
        .then(async (data: RegionResponse) => {
          let b2cProviderSubdomain = NEURALIGN_ACADEMY;
          this.isReservedRegion = data.isReserved;

          if (this.isReservedRegion) {
            this.regionOwner = data.regionOwner;
            this.regionData = data.region;

            if (this.regionOwner && this._userCountry === USCODE) {
              b2cProviderSubdomain = NEURALIGNUSA;
            }
          }

          //  TODO replace this url for the new one that delivers only the b2c token price
          await this._http
            .get<OrgResponse>(environment.API_URL + `organization/` + b2cProviderSubdomain)
            .toPromise()
            .then((response: OrgResponse) => {
              if (response) {
                this.setOrgB2cPrices(response.organization);
              }
            });
        });
    } catch (error) {
      console.error(error);
    }
  }

  private setOrgB2cPrices(organization: Organization) {
    if (!organization) {
      return;
    }

    this.b2cTokenPrice = organization.b2cTokenPrice;
    this.b2cPaymentType = organization.b2cPaymentType ? organization.b2cPaymentType : 'Single Payment';
    this.b2cCurrency = organization.b2cCurrency ? organization.b2cCurrency : 'CAD';
  }

  public get paymentType(): string {
    return this.b2cPaymentType;
  }

  public get purchaseCurrency(): string {
    return this.b2cCurrency;
  }

  public get tokenPrice(): number {
    return this.b2cTokenPrice;
  }

  public get userCountry(): string {
    return this._userCountry;
  }

  public get reservedRegionStatus(): boolean {
    return this.isReservedRegion;
  }

  public get reservedRegionOwner(): any {
    return this.regionOwner;
  }

  public get reservedRegionData() {
    return this.regionData;
  }

  public get userLanguage(): string {
    if (this._userCountry === CANADACODE) {
      return 'en_ca';
    } else if (this._userCountry === USCODE) {
      return 'en_us';
    } else if (this._userCountry === KUWAITCODE) {
      return 'ar_kw';
    }
  }
}
